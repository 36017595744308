/*
 |--------------------------------------------------------------------------
 | TailwindCSS Directives
 |--------------------------------------------------------------------------
 |
 | Import TailwindCSS directives and swipe out at build-time with all of
 | the styles it generates based on your configured design system.
 |
 | Please check this link for more details:
 | https://tailwindcss.com/docs/installation#include-tailwind-in-your-css
 |
 */
@tailwind base;
@tailwind components;
@tailwind utilities;

/*
 |--------------------------------------------------------------------------
 | 3rd Party Libraries
 |--------------------------------------------------------------------------
 |
 | Import 3rd party library CSS/SASS files.
 |
 */

/*
 |--------------------------------------------------------------------------
 | Components
 |--------------------------------------------------------------------------
 |
 | Import CSS/SCSS components.
 |
 */
//@import "components/global";
//@import "components/form";
//@import "components/table";
//@import "components/modal";
//@import "components/notification";
@import 'components/preloader';
@import 'components/datepicker';
//@import "components/reset";
//@import "components/tooltip";
//@import "components/pagination";
//@import "components/general";
